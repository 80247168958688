import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const Holder = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 3rem;
  border-top: 0.5px solid;
  padding-top: 3rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2fr 10fr;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 2fr 6fr 4fr;
  }
  header, article {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
  h2 { margin-bottom: 1rem; }
`;

class TextsList extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query TextsStaticQuery {
                allMarkdownRemark(
                  filter: {
                      fileAbsolutePath: {regex : "\/_posts/projects/"}
                  }
                ){
                  edges {
                    node{
                      html
                      frontmatter {
                        title
                        year
                        text_author
                      }
                    }
                  }
                }
              }`}
        render={data => {
          const textsData = data.allMarkdownRemark.edges.filter(
            edge => this.props.texts.includes( edge.node.frontmatter.title )
          );
          if ( !textsData ) {
            return null
          }
          return (
            textsData.map( edge => (
              <Holder>
                <header>
                  <h2>{edge.node.frontmatter.text_author}</h2>
                  <p>{edge.node.frontmatter.title}</p>
                  <p>{edge.node.frontmatter.year}</p>
                </header>
                <article dangerouslySetInnerHTML={{ __html: edge.node.html }}/>
              </Holder>
            ) )
          )
        }}
      />
    )
  }
}

export default TextsList;