import React, { Component } from 'react';
import Layout from '../components/organisms/Layout'
import SEO from '../components/molecules/SEO'
import { graphql } from 'gatsby';
import TextsList from '../components/organisms/TextsList';

class Texts extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Texts"/>
        <article>
          <TextsList texts={this.props.data.allMarkdownRemark.edges[ 0 ].node.frontmatter.texts}/>
        </article>
      </Layout>
    )
  }
}

export default Texts;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex : "/texts.md/"}
      }
    ){
      edges {
        node{
          frontmatter {
            texts
          }
        }
      }
    }
  }
`
